<template>
    <div class="reward-container">
        To be continued...
    </div>
</template>
<style scoped>
    /* 顶部样式 */
    .reward-container {
        font-size: 16px;
        margin-top: 100px;
    }

    /* 底部样式 */

    /* 表单样式 */
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'v-reward',
        components: {},
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {

        },
        computed: {
        },
        methods: {
        }
    };
</script>